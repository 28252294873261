import React, { useEffect } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { Typography } from "@material-ui/core";
import Button from "@mui/material/Button";
import Fade from "@material-ui/core/Fade";
import HtmlTooltip from "../../assets/components/HtmlTooltip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import callRatingStyles from "./CallRating.css";
import { updateCallRatingFeedback, getCallRatingsAndFeedback } from "../../services/callRatingsService";
import { VeryNegativeIcon, NegativeIcon, PositiveIcon, VeryPositiveIcon } from "../../assets/components/threshold_icons/ThresholdIcons";
import * as constants from "../../assets/constants";
import Skeleton from "@mui/material/Skeleton";

export default function CallRating(props) {
  const styles = callRatingStyles();
  const [value, setValue] = React.useState(null);
  const [hover, setHover] = React.useState(-1);
  const [labels, setLabels] = React.useState({});
  const [g1_score_threshold] = React.useState({
    low: 25,
    mid: 50,
    high: 75,
  });
  const [script_adherence_threshold] = React.useState({
    low: 0.8,
    high: 0.95,
  });
  const [spinner, setSpinner] = React.useState(true);
  const [g1_score, setG1Score] = React.useState({
    G1Score: 0,
    G1Score_KO: 0,
    G1Score_Sentiment: 0,
    G1Score_KPIs: 0,
    G1Score_TalkToListen: 0,
  });
  const [scriptDetails] = React.useState(props.dataForSnip?.data?.ScriptDetails);

  useEffect(() => {
    (async () => {
      try {
        getCallRatingsAndCallRatingFeedbackInfo();
      } catch (err) {}
    })();
  }, []);

  // getCallRatingsAndCallRatingFeedback
  const getCallRatingsAndCallRatingFeedbackInfo = async () => {
    try {
      getCallRatingsAndFeedback({ convID: props.convID }).then((response) => {
        // set rating value
        response?.data?.rating?.callRatingFeedback?.forEach((element) => {
          if (element.CallSection === "All") {
            element.CallRating === 0 ? setValue(null) : setValue(element.CallRating);
          }
        });

        // set labels
        let labelsInfo = {};
        response?.data?.rating?.callRatings?.forEach((element) => {
          if (element.Rating === 0) {
            labelsInfo[null] = element.RatingLabel;
          } else {
            labelsInfo[element.Rating] = element.RatingLabel;
          }
        });
        setLabels(labelsInfo);

        // set g1 score details
        let g1ScoreInfo = response?.data?.g1_score;
        if (g1ScoreInfo) setG1Score(g1ScoreInfo);
        setSpinner(false);
      });
    } catch (err) {}
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  function getG1ThresholdIcon(value) {
    if (value !== null) {
      if (value >= g1_score_threshold.high) return <VeryPositiveIcon />;
      else if (value < g1_score_threshold.high && value >= g1_score_threshold.mid) return <PositiveIcon />;
      else if (value < g1_score_threshold.mid && value >= g1_score_threshold.low) return <NegativeIcon />;
      else if (value < g1_score_threshold.low) return <VeryNegativeIcon />;
    } else {
      return <VeryNegativeIcon />;
    }
  }

  function getScriptAdherenceLabels(labelsMap) {
    const results = [];
    if (labelsMap !== null && labelsMap.length > 0) {
      labelsMap.forEach((label) => {
        results.push(
          <div>
            <span
              onClick={() => props.seekByFastForward(label.MessageID)}
              className={label.MessageID ? styles.script_label_button_span : styles.script_label_span}
            >
              {label.ScriptLabel}:
            </span>
            <span className={styles.script_label_threshold_icon_span}>
              {constants.getScriptAdherenceLabelThresholdIcon(script_adherence_threshold, label.AdherenceScore)}
            </span>
          </div>
        );
      });
      return results;
    } else {
      return undefined;
    }
  }

  return (
    <React.Fragment>
      <div className={styles.callRatingRoot}>
        {props.dockState ? (
          <Accordion elevation={0} defaultExpanded={true}>
            <AccordionSummary
              className={styles.accordion_default_with_hover}
              expandIcon={<ExpandMoreIcon className={styles.svgExpandMoreIconDefault} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StarIcon color="action" style={{ marginRight: "4px" }} />
              <Typography className={styles.drawer_label}>Scores</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordian_details}>
              {!spinner ? (
                <Box name="gryph_score_box" className={styles.script_adh_box}>
                  <Accordion className={styles.script_score_accordion} elevation={0} defaultExpanded={false}>
                    <AccordionSummary
                      className={styles.script_adh_acc_summary}
                      expandIcon={<ExpandMoreIcon fontSize="small" className={styles.svgExpandMoreIconBlue} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styles.script_label}>Gryph Score:</Typography>
                      <span style={{ width: "24px", height: "24px" }}>{getG1ThresholdIcon(g1_score.G1Score)}</span>
                    </AccordionSummary>
                    <AccordionDetails className={styles.script_adherence_acc_details}>
                      <div className={styles.script_adherence_labels} name="script_adherence_labels">
                        <div>
                          <span className={styles.script_label_span}>
                            <b>Overall:</b>
                          </span>
                          <span className={styles.script_label_threshold_icon_span}>
                            <b>{g1_score.G1Score}</b>
                          </span>
                        </div>
                        <div>
                          <span className={styles.script_label_span}>Key Outcome:</span>
                          <span className={styles.script_label_threshold_icon_span}>{g1_score.G1Score_KO}</span>
                        </div>
                        <div>
                          <span className={styles.script_label_span}>Sentiment:</span>
                          <span className={styles.script_label_threshold_icon_span}>{g1_score.G1Score_Sentiment}</span>
                        </div>
                        <div>
                          <span className={styles.script_label_span}>KPIs:</span>
                          <span className={styles.script_label_threshold_icon_span}>{g1_score.G1Score_KPIs}</span>
                        </div>
                        <div>
                          <span className={styles.script_label_span}>Talk to Listen:</span>
                          <span className={styles.script_label_threshold_icon_span}>{g1_score.G1Score_TalkToListen}</span>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ) : (
                <Skeleton variant="rectangular" width={210} height={25} />
              )}

              <Box name="script_adh_box" className={styles.script_adh_box}>
                {scriptDetails?.AdherenceScoresMap?.length > 0 ? (
                  <Accordion className={styles.script_score_accordion} elevation={0} defaultExpanded={false}>
                    <AccordionSummary
                      className={styles.script_adh_acc_summary}
                      expandIcon={<ExpandMoreIcon fontSize="small" className={styles.svgExpandMoreIconBlue} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styles.script_label}>Script Adherence:</Typography>
                      <span style={{ width: "24px", height: "24px" }}>
                        {constants.getScriptAdherenceThresholdIcon(script_adherence_threshold, scriptDetails.AdherenceScoresMap)}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails className={styles.script_adherence_acc_details}>
                      <div className={styles.script_adherence_labels} name="script_adherence_labels">
                        {getScriptAdherenceLabels(scriptDetails?.LabelsMap)}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ) : undefined}
              </Box>

              <Box name="rating_box" className={styles.rating_box}>
                <Typography className={styles.rating_label}>Rating:</Typography>
                <div style={{ display: "flex" }}>
                  <Rating
                    size="small"
                    name="hover-feedback"
                    value={value}
                    readOnly={!props.isAdmin || props.externalAccess}
                    defaultValue={0}
                    precision={1}
                    getLabelText={getLabelText}
                    onChange={async (event, newValue) => {
                      setValue(newValue);
                      await updateCallRatingFeedback(props.convID, newValue, "All", props.userKey);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                  {
                    <Box className={styles.rating_box_value}>
                      {Object.keys(labels).length > 0 ? labels[hover !== -1 ? hover : value] : "No rating"}
                    </Box>
                  }
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <div className={styles.callRatingsDocked}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className={styles.participantText}>Scores</Typography>
                </React.Fragment>
              }
              placement="right"
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <Button variant="text" className={styles.drawer_rating_button} onClick={props.handleDrawerOpen}>
                <StarIcon color="action" />
              </Button>
            </HtmlTooltip>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
